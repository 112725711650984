input[type="file"] {
  display: none;
}

.custom-file-input {
  padding: 10px 0px;
  margin: 20px 2px;
  display: block;
  background-color: var(--ion-color-primary);
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  display: block;
  margin-top: 10px;
  cursor: pointer;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.84px;
  text-decoration: none solid rgb(255, 255, 255);
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  word-spacing: 0px;
  border-radius: 5px;
}

.custom-file-input:hover {
  background-color: var(--ion-color-primary-shade);
  transform: none;
  transition: all 0s ease 0s;
  box-sizing: border-box;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
