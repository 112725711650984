.ion-row-login {
  padding: 0 20px;
}

.center-login {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 40%;
  transform: translateY(-50%);
}
